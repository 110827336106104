<template>
  <v-container fluid class="py-6">
    <v-row>
      <common-loading
        :title="loading.title"
        :message="loading.message"
        :loading_dialog="loading.dialog"
        :show_loading="loading.show_loading"
        :show_confirm="loading.show_confirm"
        v-on:confirm="loading.dialog = false"
      >
      </common-loading>
      <common-confirm
        :title="confirm.title"
        :message="confirm.message"
        :confirm_dialog="confirm.dialog"
        :confirm_function="confirm.function"
        v-on:confirm="confirm.dialog = false"
        v-on:cancel="confirm.dialog = false"
      ></common-confirm>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="px-0 py-0">
            <v-data-table
              :headers="headers"
              :items="bindings"
              :items-per-page="itemsPerPage"
              mobile-breakpoint="0"
              :options.sync="options"
              :server-items-length="binding_count"
              :loading="loading_table"
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <!-- <label class="font-weight-bolder py-2 px-2 me-2"
                      >關聯型態</label
                    >
                    <v-menu transition="slide-y-transition" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          :ripple="false"
                          height="43"
                          class="
                            font-weight-bold
                            text-uppercase
                            btn-default btn-outline-default
                            py-2
                            px-6
                            me-2
                          "
                          color="transparent"
                          small
                        >
                          {{ filter_title }}
                          <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
                        </v-btn>
                      </template>

                      <v-list class="py-0">
                        <v-list-item
                          class="list-item-hover-active py-2"
                          @click="setType(bind_type_enum.Unknown)"
                        >
                          <v-list-item-content class="pa-0">
                            <v-list-item-title
                              class="
                                text-body-2
                                ls-0
                                text-typo
                                font-weight-600
                                mb-0
                              "
                            >
                              未指定
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          class="list-item-hover-active pb-2"
                          @click="setType(bind_type_enum.Manual)"
                        >
                          <v-list-item-content class="pa-0">
                            <v-list-item-title
                              class="
                                text-body-2
                                ls-0
                                text-typo
                                font-weight-600
                                mb-0
                              "
                            >
                              智能關聯
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          class="list-item-hover-active pb-2"
                          @click="setType(bind_type_enum.Auto)"
                        >
                          <v-list-item-content class="pa-0">
                            <v-list-item-title
                              class="
                                text-body-2
                                ls-0
                                text-typo
                                font-weight-600
                                mb-0
                              "
                            >
                              自動合併
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <hr class="horizontal dark" />
                        <v-list-item
                          class="list-item-hover-active py-2"
                          @click="setType(null)"
                        >
                          <v-list-item-content class="pa-0">
                            <v-list-item-title
                              class="text-danger ls-0 font-weight-600 mb-0"
                            >
                              全部
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu> -->
                    <v-spacer></v-spacer>
                    <v-btn
                      :ripple="false"
                      elevation="0"
                      color="#fff"
                      class="
                        font-weight-bolder
                        btn-primary
                        bg-gradient-primary
                        py-5
                        px-6
                        mx-1
                      "
                      @click="onAutoMerge()"
                      small
                    >
                      觸發自動合併
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      elevation="0"
                      color="#fff"
                      class="
                        font-weight-bolder
                        btn-primary
                        bg-gradient-default
                        py-5
                        px-6
                        mx-1
                      "
                      @click="onUnAutoMerge()"
                      small
                    >
                      解除自動合併
                    </v-btn>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:item.smart_related="{ item }">
                <v-checkbox
                  v-model="item.smart_related"
                  class="mt-4"
                  dense
                  @change="item.change = true"
                ></v-checkbox>
              </template>
              <template v-slot:item.auto_merge="{ item }">
                <v-checkbox
                  v-model="item.auto_merge"
                  class="mt-4"
                  dense
                  @change="item.change = true"
                ></v-checkbox>
              </template>
              <!-- <template v-slot:item.type="{ item }">
                <v-select
                  :items="bind_type_enum_str"
                  v-model="item.type"
                  class="font-size-input border border-radius-md mt-2"
                  outlined
                  single-line
                  hide-details
                  @change="item.change = true"
                >
                </v-select>
              </template> -->
              <template v-slot:item.actions="{ item }">
                <div>
                  <v-btn
                    class="
                      font-weight-bold
                      text-xs
                      btn-default
                      bg-gradient-secondary
                      py-5
                      px-6
                      mt-2
                      mb-2
                      me-2
                      ms-auto
                    "
                    :disabled="!item.change"
                    @click="updateBindingType(item)"
                  >
                    更新
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" class="ml-auto d-flex justify-end">
        <v-pagination
          prev-icon="fa fa-angle-left"
          next-icon="fa fa-angle-right"
          class="pagination"
          color="#cb0c9f"
          v-model="page"
          :length="getPageCount()"
          circle
          :total-visible="15"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import CommonUtility from "@/util/CommonUtility.js";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import { bind_type_enum, bind_type_enum_str } from "@/customers.js";

export default {
  name: "Binding-List",
  data: () => ({
    bind_type_enum: bind_type_enum,
    bind_type_enum_str: bind_type_enum_str,
    page: 1,
    itemsPerPage: 20,
    binding_count: 0,
    bindings: [],
    loading_table: false,
    type: null,
    options: {},
    loading: {
      dialog: false,
      title: null,
      message: null,
      show_loading: true,
      show_confirm: false,
    },
    confirm: {
      dialog: false,
      title: null,
      message: null,
      function: null,
    },
    filter_title: "全部",
    headers: [
      // {
      //   text: "ID",
      //   value: "id",
      //   class: "text-secondary font-weight-bolder opacity-7",
      //   width: "10%",
      // },
      {
        text: "規則",
        value: "rule",
        class: "text-secondary font-weight-bolder opacity-7",
        width: "30%",
      },
      {
        text: "範例",
        value: "description",
        class: "text-secondary font-weight-bolder opacity-7",
        width: "30%",
      },
      // {
      //   text: "智能關聯",
      //   value: "smart_related",
      //   class: "text-secondary font-weight-bolder opacity-7",
      //   width: "20%",
      // },
      {
        text: "自動合併",
        value: "auto_merge",
        class: "text-secondary font-weight-bolder opacity-7",
        width: "20%",
      },
      {
        text: "動作",
        value: "actions",
        class: "text-right font-weight-bolder opacity-7",
        width: "10%",
      },
    ],
  }),
  components: {
    CommonLoading,
    CommonConfirm,
  },
  mixins: [HttpCommonMixin],
  mounted() {
    this.getBindings();
  },
  watch: {
    page() {
      this.getBindings();
    },
  },
  methods: {
    getPageCount() {
      return Math.ceil(this.binding_count / this.itemsPerPage);
    },

    getBindings() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-bind/`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
        type: this.type,
      };
      this.loading_table = true;
      this.doHttpGet(url, params, this.setResult, null, this.setDone);
    },
    setResult(result) {
      // console.log("result:", result.items);
      //  add change attribute of each item of result.items
      result.items.forEach((item) => {
        item.change = false;
      });

      this.bindings = result.items;
      this.binding_count = result.total;
    },
    setDone() {
      this.loading_table = false;
    },
    setType(type) {
      this.type = type;
      switch (type) {
        case bind_type_enum.Auto:
          this.filter_title = "自動合併";
          break;
        case bind_type_enum.Manual:
          this.filter_title = "智能關聯";
          break;
        case bind_type_enum.Unknown:
          this.filter_title = "未指定";
          break;
        default:
          this.filter_title = "全部";
          break;
      }
      this.getBindings();
    },
    updateBindingType(item) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-bind/${item.id}`;
      let params = {
        auto_merge: item.auto_merge,
        smart_related: item.smart_related,
      };
      // this.loading_table = true;
      this.loading = {
        dialog: true,
        title: "更新中",
        message: "更新客戶關係條件",
        show_loading: true,
        show_confirm: false,
      };
      this.doHttpPut(
        url,
        params,
        this.setUpdateResult,
        this.setUpdateFail,
        this.setDone
      );
    },
    setUpdateResult(result) {
      // console.log("result:", result);
      this.loading = {
        dialog: true,
        title: "更新成功",
        message: "更新客戶關係條件完成",
        show_loading: false,
        show_confirm: true,
      };
      this.getBindings();
    },
    setUpdateFail(data) {
      console.log("data:", data);
      const msg = CommonUtility.parseError2(data);
      this.loading = {
        dialog: true,
        title: "更新失敗",
        message: msg,
        show_loading: false,
        show_confirm: true,
      };
    },
    onAutoMerge() {
      this.confirm = {
        dialog: true,
        title: "確認",
        message: "觸發自動合併?",
        function: this.setAutoMerge,
      };
    },
    setAutoMerge() {
      this.loading = {
        dialog: true,
        title: "合併",
        message: "自動合併中",
        show_loading: true,
        show_confirm: false,
      };
      this.confirm.dialog = false;
      //
      setTimeout(() => {
        const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-bind/auto-all-merge`;
        this.doHttpPost(
          url,
          null,
          this.setAutoMergeSuccess,
          this.setAutoMergeFail,
          this.setDone
        );
      }, 500);
    },
    setAutoMergeSuccess(data) {
      console.log("data:", data);
      let count = data.count;
      this.loading = {
        dialog: true,
        title: "更新成功",
        message: `更新客戶${count}筆`,
        show_loading: false,
        show_confirm: true,
      };
    },
    setAutoMergeFail(data) {
      console.log("data:", data);
      const msg = CommonUtility.parseError2(data);
      this.loading = {
        dialog: true,
        title: "更新失敗",
        message: msg,
        show_loading: false,
        show_confirm: true,
      };
    },
    onUnAutoMerge() {
      this.confirm = {
        dialog: true,
        title: "確認",
        message: "解除自動合併?",
        function: this.setUnAutoMerge,
      };
    },
    setUnAutoMerge() {
      this.loading = {
        dialog: true,
        title: "解除合併",
        message: "解除自動合併中",
        show_loading: true,
        show_confirm: false,
      };
      this.confirm.dialog = false;
      //
      setTimeout(() => {
        const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-bind/auto-all-un-merge`;
        this.doHttpPost(
          url,
          null,
          this.setUnAutoMergeSuccess,
          this.setUnAutoMergeFail,
          this.setDone
        );
      }, 500);
    },
    setUnAutoMergeSuccess(data) {
      let count = data.count;
      this.loading = {
        dialog: true,
        title: "更新成功",
        message: `更新客戶${count}筆`,
        show_loading: false,
        show_confirm: true,
      };
    },
    setUnAutoMergeFail(data) {
      const msg = CommonUtility.parseError2(data);
      this.loading = {
        dialog: true,
        title: "更新失敗",
        message: msg,
        show_loading: false,
        show_confirm: true,
      };
    },
  },
};
</script>
